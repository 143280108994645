
import Vue from "../../../shared/node_modules/vue";
import Component from "../../../shared/node_modules/vue-class-component";
import { CrudService } from "../../../shared/core/services/crudService";
import { LocalStorage } from "@shared/core/localStorage";
import { Settings } from "@shared/core/services/configService";

@Component({})
export default class linkToLevelProviderPage extends Vue {

  async created() {
    var providerUserId = this.$route.query.user_id;
    if (!providerUserId) {
      alert("error");
      return;
    }
    try {
      const customerId = JSON.parse(LocalStorage.getWithTimeout("cc")).id;
      await this.linkToLevelProvider(customerId, providerUserId);
    } catch {
      console.error("not customer logged found");
      return;
    }
  }

  private async linkToLevelProvider(customerId, providerUserId) {
    var provider = this.$route.params.provider;
    let url = `${Settings.HostName}/api/publicbookings/levels/${customerId}/provider/${provider}/link`;
    var response = await new CrudService().httpPost(url, providerUserId);
    if (response?.ok) {
      this.$router.push({ name: "levels" });
    }
  }
}
