
import { CrudService } from "../core/services/crudService";
import { Settings, TenantSettings } from "../core/services/configService";
import Vue from "../node_modules/vue";
import Component from "../node_modules/vue-class-component";
import { LocalStorage } from "../core/localStorage";
import VueSlickCarousel from "vue-slick-carousel";
//import { Carousel, Slide } from "vue-carousel";
//import "vue-slick-carousel/dist/vue-slick-carousel.css";
import { DateHelper } from "../core/helpers/dateHelper";
import { Format } from "../core/helpers/formatHelper";
import Popup from "../components/popup/popup.vue";
import { ShopService } from "../core/services/shopService";
import DatePicker from "../components/datePicker/datePicker.vue";
import { DatePickerMode, IsMobile } from "../core/constants";
import { Global } from "../core/helpers/global";
import BookingsFilters from "./bookingsFilters.vue";
import SubmitButton from "../components/submitButton/submitButton.vue";
import store from "../core/store";

@Component({
  components: {
    VueSlickCarousel,
    // Carousel,
    // Slide,
    Popup,
    DatePicker,
    BookingsFilters,
    SubmitButton,
  },
})
export default class BookingsByPlacePage extends Vue {
  settings: TenantSettings = {};
  anyByType: boolean = false;
  bookAllowed: boolean = false;
  errorMsg: string = "";
  date: string | undefined = "";
  bookingTypeId: number | null = null;
  data: any[] = [];
  dataByDays: any[] = [];
  bookingTypes: any[] = [];
  bookingTypesByPlace: any[] = [];
  showPopUpConfirm: boolean = false;
  bookingSelected: any = {};
  carouselSettings: any = null;
  loaded: boolean = false;

  closeFilter: number = 0;
  openFilter: number = 0;

  created() {
    if (this.$route.query.date !== undefined) {
      this.date = this.$route.query.date?.toString();
    }

    //if (this.date === null || this.date === "" || this.data === undefined) {
    // this.date = DateHelper.toSystemString(store.getters.localNow);
    //}
  }

  async mounted() {
    this.settings = store.state.settings;
    let url = `${Settings.HostName}/api/publicbookings/filters`;
    let res = await new CrudService().httpGet(url);
    if (res == null || res.ok === false) {
      console.error("Crash getting filters");
      this.errorMsg = this.$t("Crash").toString();
      return;
    }

    this.bookingTypes = res.model.bookingTypes;
    if (
      this.bookingTypes === null ||
      this.bookingTypes === undefined ||
      this.bookingTypes.length === 0
    ) {
      this.errorMsg = this.$t("No bookings by place available").toString();
      return;
    }

    this.bookingTypesByPlace = this.bookingTypes.filter(
      (y) => y.byPlace === true
    );
    if (this.bookingTypes.length === 1) {
      this.bookingTypeId = this.bookingTypes[0].id;
      this.bookingTypes = [];
    }

    if (this.$route.params.bookingTypeId !== undefined) {
      this.bookingTypeId = Number(this.$route.params.bookingTypeId);
    }

    await this.loadData();
    this.loaded = true;
    if (this.$route.query.bookingId !== undefined) {
      this.bookingSelected = this.data.find(
        (y) => y.id == Number(this.$route.query.bookingId)
      );

      if (this.bookingSelected === undefined) {
        console.error(
          "wrong url booking id param" + this.$route.query.bookingId
        );
        this.errorMsg = this.$t("crash").toString();
        return;
      }
      this.showPopUpConfirm = true;
    }
  }

  getCarrouselSettings(length: number) {
    return {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      centerMode: length <= 3 && length != 0 ? true : false,
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            //dots: true,
          },
        },
        {
          breakpoint: 1264,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            //dots: true,
          },
        },
        {
          breakpoint: 888,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  }

  async loadData() {
    this.errorMsg = "";
    let cid = 0;
    let sid = LocalStorage.getWithTimeout("cc");
    if (sid !== undefined && sid !== null) {
      cid = JSON.parse(sid).id;
    }

    this.data = [];
    this.dataByDays = [];

    const url = `${Settings.HostName}/api/publicbookings/byplace-list`;

    Global.TriggerDomId = "loader";
    let payLoad: any = {
      bookingTypeId: this.bookingTypeId,
      customerId: cid === 0 ? null : cid,
    };

    if (!Format.IsNull(this.data)) {
      payLoad.date = this.date;
    }

    const res = await new CrudService().httpPost(url, payLoad);
    if (res == null || res == undefined) {
      console.error("Crash getting bookings by place");
      return;
    }

    if (res.ok === false) {
      this.errorMsg = res.errorMsg;
      return;
    }

    if (res.data === null || res.data === undefined || res.data.length === 0) {
      this.errorMsg =
        this.$t("Oops! there is nothing you can join").toString() +
        ". " +
        this.$t("Try changing the date").toString();

      return;
    }

    this.data = res.data;
    this.setUpData();
  }

  setUpData() {
    this.dataByDays = [];
    const mondays = this.data.filter((y) =>
      DateHelper.isInDayOfWeek(DateHelper.toSystemDate(y.start), 1)
    );

    if (mondays.length > 0) {
      this.dataByDays.push({
        title: this.$t("Mondays"),
        data: mondays,
      });
    }

    const tuesdays = this.data.filter((y) =>
      DateHelper.isInDayOfWeek(DateHelper.toSystemDate(y.start), 2)
    );

    if (tuesdays.length > 0) {
      this.dataByDays.push({
        title: this.$t("Tuesdays"),
        data: tuesdays,
      });
    }

    const wenesdays = this.data.filter((y) =>
      DateHelper.isInDayOfWeek(DateHelper.toSystemDate(y.start), 3)
    );

    if (wenesdays.length > 0) {
      this.dataByDays.push({
        title: this.$t("Wenesdays"),
        data: wenesdays,
      });
    }

    const thursdays = this.data.filter((y) =>
      DateHelper.isInDayOfWeek(DateHelper.toSystemDate(y.start), 4)
    );

    if (thursdays.length > 0) {
      this.dataByDays.push({
        title: this.$t("Thursdays"),
        data: thursdays,
      });
    }

    const fridays = this.data.filter((y) =>
      DateHelper.isInDayOfWeek(DateHelper.toSystemDate(y.start), 5)
    );

    if (fridays.length > 0) {
      this.dataByDays.push({
        title: this.$t("Fridays"),
        data: fridays,
      });
    }

    const saturdays = this.data.filter((y) =>
      DateHelper.isInDayOfWeek(DateHelper.toSystemDate(y.start), 6)
    );

    if (saturdays.length > 0) {
      this.dataByDays.push({
        title: this.$t("Saturdays"),
        data: saturdays,
      });
    }

    const sundays = this.data.filter((y) =>
      DateHelper.isInDayOfWeek(DateHelper.toSystemDate(y.start), 0)
    );

    if (sundays.length > 0) {
      this.dataByDays.push({
        title: this.$t("Sundays"),
        data: sundays,
      });
    }
  }

  book(e: any, id: number) {
    e.preventDefault();

    this.errorMsg = "";
    const booking = this.data.find((y) => y.id === id);
    if (booking === undefined) {
      console.error("not booking found " + id);
    }

    const url =
      booking.bookingTemplateId !== null &&
      booking.bookingTemplateId !== undefined
        ? "/booking-visual/" +
          id +
          "/" +
          booking.bookingTemplateId +
          "?date=" +
          this.date
        : "/bookings-byplace?bookingId=" + id + "&date=" + this.date;

    if (this.$store.state.loggedIn === false) {
      this.$store.commit("openLogin", { backUrl: url });
      return;
    }

    if (
      booking.bookingTemplateId !== null &&
      booking.bookingTemplateId !== undefined
    ) {
      return this.$router.push(url);
    }

    this.bookingSelected = booking;
    this.showPopUpConfirm = true;

    // return this.$router.push(
    //   "/bookings-byplace?bookingId=" + id + "&date=" + this.date
    // );
  }

  async addToCart(e: any) {
    this.errorMsg = "";
    e.preventDefault();
    let cid = 0;
    let sid = LocalStorage.getWithTimeout("cc");
    if (sid !== undefined && sid !== null) {
      cid = JSON.parse(sid).id;
    }
    if (cid === 0 || cid === null) {
      this.errorMsg = this.$t("Please login or register").toString();
      return;
    }

    const bookingId = this.bookingSelected.id;
    if (bookingId === null || bookingId === undefined) {
      this.errorMsg = this.$t("crash").toString();
      return;
    }

    let url = `${
      Settings.HostName
    }/api/publicbookings/byplace-prebook-place/${cid}/${bookingId}/${this.getOrigin()}`;
    if (this.$route.query.placeId !== undefined) {
      url += "/" + this.$route.query.placeId;
    }
    const res = await new CrudService().httpPost(url, {});

    if (res == null || res == undefined) {
      console.error("Crash getting bookings by place");
      this.errorMsg = this.$t("Crash").toString();
      return;
    }
    if (res.ok === false) {
      this.errorMsg = res.errorMsg;
      return;
    }

    if (res.model.serviceFree === true) {
      return this.$router.push("/customer-bookings");
    }

    let sale = res.model;
    sale.title = sale.description;
    sale.cancelUrl = `publicbookings/cancel/${bookingId}/${cid}`;

    new ShopService().addSaleToCart(sale, e.currentTarget);
    this.$router.push("/checkout");
  }

  getOrigin() {
    return Format.getOrigin();
  }
  getPrice(price: string, netPrice: string) {
    return store.state.settings.PriceWithTaxes
      ? Format.formatCurrency(price)
      : Format.formatCurrency(netPrice);
  }

  bookingTypeSelected(bType: any) {
    if (
      bType.byPlace === true &&
      (Format.IsNull(bType.allowCreateBookByPlace) ||
        bType.allowCreateBookByPlace == false)
    ) {
      this.filterByBookingType(bType.id, null);
      return;
    }
    return this.$router.push("/bookings/" + bType.id);
  }

  filterByBookingType(id: number, e: any) {
    if (e !== null) {
      e.preventDefault();
    }
    this.bookingTypeId = id;
    this.loadData();
  }

  filterByDate(date: any) {
    if (this.loaded === false) {
      return;
    }
    this.date = date.systemStrDate;
    this.loadData();
  }
  datePickerMode() {
    return DatePickerMode.Date;
  }

  closeFilters() {
    this.closeFilter++; // to trigger watch
  }

  openFilters() {
    this.openFilter++; // to trigger watch
  }

  isMobile() {
    return IsMobile;
  }

  getImagePath(img: string) {
    if (img === null || img === undefined || img === "") {
      return this.settings.Uploads + "/" + this.settings.Mainlogo;
    }
    return this.settings.Uploads + "/" + img;
  }
}
