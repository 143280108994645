import Vue from '@modules/vue';
import VueRouter from '@modules/vue-router';
import store from '@shared/core/store';
import { LocalStorage } from '@shared/core/localStorage';
Vue.use(VueRouter);

/************************************************************
 meta options:
 public default false
 layout [secure | insecure ] default insecure
/************************************************************/
const routes = [

    { path: '/', name: "index", meta: { public: true }, component: require('../pages/index.vue').default, props: true },
    { path: '/changePass', name: "changepass", meta: { public: false }, component: require('../../../shared/pages/changePasswordPage.vue').default, props: true },
    { path: '/shop', name: "shop", meta: { public: true }, component: require('../../../shared/pages/shopPage.vue').default, props: true },
    { path: '/shop/:id', name: "shop-product", meta: { public: true }, component: require('../../../shared/pages/productPage.vue').default, props: true },
    { path: '/blog', name: "blog", meta: { public: true }, component: require('../../../shared/pages/blogPage.vue').default, props: true },
    { path: '/post/:id', name: "post", meta: { public: true }, component: require('../../../shared/pages/postPage.vue').default, props: true },
    { path: '/checkout', name: "checkout", meta: { public: true }, component: require('../../../shared/pages/checkoutPage.vue').default, props: true },
    { path: '/debts', name: "debts", meta: { public: false }, component: require('../../../shared/pages/debtsPage.vue').default, props: true },
    { path: '/cz/ok/', name: "payment-ok", meta: { public: true }, component: require('../../../shared/pages/paymentOkPage.vue').default, props: true },
    { path: '/cz/ko/', name: "payment-ko", meta: { public: true }, component: require('../../../shared/pages/paymentKoPage.vue').default, props: true },
    { path: '/netpay-callback/', name: "netpay-callback", meta: { public: true }, component: require('../../../shared/pages/netPay3dsPayment.vue').default, props: true },
    { path: '/stripe-callback/', name: "stripe-callback", meta: { public: true }, component: require('../../../shared/pages/stripeRedirectPayment.vue').default, props: true },
    { path: '/transbank/process-payment/:id', name: "transbank-callback", meta: { public: true }, component: require('../../../shared/pages/transbankRedirectPayment.vue').default, props: true },
    { path: '/customer/reset-password/:token', name: "reset-password", meta: { public: true }, component: require('../../../shared/pages/resetPasswordPage.vue').default, props: true },
    { path: '/customer/activate/:token', name: "activate", meta: { public: true }, component: require('../../../shared/pages/activatePage.vue').default, props: true },
    { path: '/passReset/ok/', name: "pass-reset-ok", meta: { public: true }, component: require('../../../shared/pages/passResetOkPage.vue').default, props: true },
    { path: '/privacy', name: "privacy", meta: { public: true }, component: require('../../../shared/pages/privacyPage.vue').default, props: true },
    { path: '/waiver', name: "waiver", meta: { public: true }, component: require('../../../shared/pages/waiverPage.vue').default, props: true },
    { path: '/cookies', name: "cookies", meta: { public: true }, component: require('../../../shared/pages/cookiesPage.vue').default, props: true },
    { path: '/generalConditions', name: "general-conditions", meta: { public: true }, component: require('../../../shared/pages/generalConditionsPage.vue').default, props: true },
    { path: '/activity/:id', name: "activity", meta: { public: true }, component: require('../../../shared/crm/components/activities/activityPage.vue').default, props: true },
    { path: '/customer-vouchers', name: "customer-vouchers", meta: { public: false }, component: require('../../../shared/pages/myVouchersPage.vue').default, props: true },
    { path: '/customer-comms', name: "customer-comms", meta: { public: false }, component: require('../../../shared/pages/myCommsPage.vue').default, props: true },
    { path: '/customer-comms/emails/:id', name: "customer-comm", meta: { public: false }, component: require('../../../shared/pages/myEmailPage.vue').default, props: true },
    { path: '/customer-orders', name: "customer-orders", meta: { public: false }, component: require('../../../shared/pages/myOrdersPage.vue').default, props: true },
    { path: '/customer-fees', name: "customer-fees", meta: { public: false }, component: require('../../../shared/pages/myFeesPage.vue').default, props: true },
    { path: '/customer-orders/:id', name: "customer-order", meta: { public: false }, component: require('../../../shared/pages/myOrderPage.vue').default, props: true },
    { path: '/link-account', name: "link-account", meta: { public: true, layout: 'secure' }, component: require('../../../shared/pages/linkAccountPage.vue').default, props: true },
    { path: '/publictickets/:token', name: "download-tickets", meta: { public: true, layout: 'secure' }, component: require('../../../shared/pages/downloadTicket.vue').default, props: true },
    { path: '/publicinvoices/:token', name: "download-invoices", meta: { public: true, layout: 'secure' }, component: require('../../../shared/pages/downloadInvoice.vue').default, props: true },

    { path: '/bookings-byplace/:bookingTypeId?', name: "bookings-byplace", meta: { public: true }, component: require('../../../shared/bookings/bookingsByPlacePage.vue').default, props: true },
    { path: '/booking-visual/:bookingId/:templateId', name: "booking-visual", meta: { public: false }, component: require('../../../shared/bookings/bookingVisualPage.vue').default, props: true },
    { path: '/booking-join/:bookingId', name: "booking-join", meta: { public: true }, component: require('../../../shared/bookings/bookingJoinPage.vue').default, props: true },
    { path: '/booking-results/:bookingId', name: "booking-results", meta: { public: true }, component: require('../../../shared/bookings/bookingResultsPage.vue').default, props: true },
    { path: '/customer-bookings', name: "customer-bookings", meta: { public: false }, component: require('../../../shared/bookings/myBookingsPage.vue').default, props: true },
    { path: '/booking/:bookingToken', name: "public-booking", meta: { public: true }, component: require('../../../shared/bookings/myBookingsPage.vue').default, props: true },
    { path: '/bookings/:bookingTypeId?', name: "bookings", meta: { public: true }, component: require('../../../shared/bookings/bookingsPage.vue').default, props: true },
    { path: '/bookingtypes', name: "bookingtypes", meta: { public: true }, component: require('../../../shared/bookings/bookingTypesPage.vue').default, props: true },
    { path: '/checkout-booking', name: "checkout-booking", meta: { public: true }, component: require('../../../shared/bookings/checkoutBookingPage.vue').default, props: true },
    { path: '/auto-login', name: "auto-login", meta: { public: true }, component: require('../../../shared/pages/autoLoginPage.vue').default, props: true },

    // overrides
    { path: '/account', name: "account", meta: { public: false }, component: require('../pages/accountPage.vue').default, props: true },

    //padel
    // { path: '/calendar', name: "calendar", meta: { public: false }, component: require('../pages/calendarPage.vue').default, props: true },
    { path: '/trainings', name: "trainings", meta: { public: false }, component: require('../pages/trainingsPage.vue').default, props: true },
    { path: '/trainings/:id', name: "training", meta: { public: false }, component: require('../pages/trainingPage.vue').default, props: true },
    // { path: '/tournaments', name: "tournaments", meta: { public: false }, component: require('../pages/tournamentsPage.vue').default, props: true },
    // { path: '/matches/:id', name: "match", meta: { public: false }, component: require('../pages/matchPage.vue').default, props: true },
    { path: '/levels', name: "levels", meta: { public: false }, component: require('../pages/levelsPage.vue').default, props: true },
    { path: '/levels/link-to-provider/:provider', name: "levels", meta: { public: false }, component: require('../pages/linkToLevelProviderPage.vue').default, props: true },
];



export const router = new VueRouter({ mode: 'history', routes: routes });

router.beforeEach(async (to, from, next) => {
    const loading = from.name === '' || from.name === null;
    if (to.path != from.path) {
        Vue.prototype.$prevRoute = from;
    }

    Vue.prototype.$last = from.fullPath;

    if (to.hash.indexOf("#/cz/ok") !== -1) {
        return next({ path: '/cz/ok' });
    }
    if (to.hash.indexOf("#/cz/ko") !== -1) {
        return next({ path: '/cz/ko' });
    }

    if (to.meta?.public !== true) {
        const t = LocalStorage.getWithTimeout("ctk");
        const isLogged = t !== undefined && t !== null && t !== '';
        if (isLogged) {
            return next();
        }

        if (store.state.loginIsOpen !== true) {
            store.commit('openLogin', to.fullPath);
        }

        if (loading) {
            return next({ path: '/' });
        }

        return next(from.fullPath);
    }

    return next();

});

