
import { CrudService } from "../../../shared/core/services/crudService";
import { Settings } from "../../../shared/core/services/configService";
import Vue from "../../../shared/node_modules/vue";
import Component from "../../../shared/node_modules/vue-class-component";
import { LocalStorage } from "../../../shared/core/localStorage";
import { DateHelper } from "../../../shared/core/helpers/dateHelper";
import { LevelProvider } from "@shared/crm/constants";

@Component({
  components: {},
})
export default class LevelsPage extends Vue {

  private readonly LevelProvider = LevelProvider;

  private data: any;
  private errorMsg: string;
  private customerId: number;
  private isDataLoading: boolean;
  private linkToProviderUrl: string | null;

  constructor() {
    super();
    this.data = null;
    this.errorMsg = "";
    this.customerId = 0;
    this.isDataLoading = false;
    this.linkToProviderUrl = null;
  }

  async mounted() {
    try {
      this.customerId = JSON.parse(LocalStorage.getWithTimeout("cc")).id;
    } catch {
      console.error("not customer logged found");
      return;
    }

    await this.loadData(LevelProvider.Pandora);
  }

  private async loadData(provider: LevelProvider) {
    this.isDataLoading = true;
    this.data = null;
    this.errorMsg = "";
    let url = `${Settings.HostName}/api/publicbookings/levels/${this.customerId}/provider/${provider}`;
    let data = await new CrudService().httpGet(url);
    if (data == null || data.ok === false) {
      this.linkToProviderUrl = await this.getLinkToProviderUrl(provider);
      if (this.linkToProviderUrl == null) {
        this.errorMsg = data.errorMsg;
      }
      this.isDataLoading = false;
      return;
    }

    this.data = data.model;
    this.isDataLoading = false;
  }

  private async onLevelProviderChange(event) {
    await this.loadData(event.target.value);
  }

  private async getLinkToProviderUrl(provider: LevelProvider): Promise<string|null> {
    let url = `${Settings.HostName}/api/publicbookings/levels/${this.customerId}/provider/${provider}/link`;
    let data = await new CrudService().httpGet(url);
    if (data == null || data.ok === false) {
      return null;
    }
    return data.model;
  }

  private getDate(date: string) {
    return DateHelper.toCultureString(date);
  }
}
