
import { CrudService } from "../core/services/crudService";
import { Settings, TenantSettings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import { LocalStorage } from "../core/localStorage";
import { Format } from "../core/helpers/formatHelper";
import { BookingOrigin, BookingPaymentType, ModifyMode, PaymentMethod, TransbankPaymentQuery } from "../core/constants";
import { ShopService } from "../core/services/shopService";
import InputText from "../components/form/inputText.vue";
import InputPassword from "../components/form/inputPassword.vue";
import CreditCardForm from "../components/form/creditCardForm.vue";
import { SaleItem } from "../core/interfaces/saleItem";
import { PaymentMethodType } from "../core/constants";
import Popup from "../components/popup/popup.vue";
import Checkbox from "../components/form/checkBox.vue";
import SubmitButton from "../components/submitButton/submitButton.vue";
import TriggerAnchor from "../components/triggerAnchor/triggerAnchor.vue";
import LegalCheck from "../components/legalCheck/legalCheck.vue";
import PubliCheck from "../components/publiCheck/publiCheck.vue";
import TransbankPM from "../components/paymentMethods/transbankPM.vue";
import LoadScript from "vue-plugin-load-script";
import { Prop } from "vue-property-decorator";
import { Loader } from "@shared/core/helpers/loaderHelper";

type CheckoutParams = {
  saleItems: any; saleIds: any; paymentMethodId: number | null;
  tokenId: number | null; promoCode: string; shopTransportId: any;
  saveCreditCard: boolean; guid: any; tpvOnlineInfoTemp: string;
  tpvOnlineNotPersitedInfo: string;
  customer: {
    id: any; name: any; surname: any; addressLine1: any; city: any;
    town: any; country: any; postalCode: any; email: any; phone: any;
    password: any; okRss: boolean; acceptConditions: boolean;
  };
  origin: BookingOrigin;
};

// @ts-ignore
Vue.use(LoadScript);

@Component({
  components: {
    InputText,
    InputPassword,
    Popup,
    Checkbox,
    CreditCardForm,
    SubmitButton,
    TriggerAnchor,
    LegalCheck,
    PubliCheck,
    TransbankPM,
  },
})
export default class CheckoutPage extends Vue {
  @Prop() data!: any;
  @Prop() transport!: any;
  @Prop() cid!: any;

  private readonly PaymentMethodType = PaymentMethodType;

  settings: TenantSettings = {};
  error: string = "";
  ok: string = "";
  errors: any = {};
  reloadErrors: number = 0;
  paymentMethodId: number | null = null;
  tokenId: number | null = null;
  saveCreditCard: boolean = false;
  acceptConditions: boolean = false;
  okRss: boolean = false;
  customer: any = null;

  promoCode: string = "";
  errorPromo: string = "";
  okPromo: string = "";
  discount: number = 0;
  codeApplied: boolean = false;

  paypal: any = {};
  paypalId: string = "";
  paypalIsLoaded: boolean = false;
  paypalPaymentMethod: any = undefined;
  reloadPayButtonText: number = 0;

  stripePaymentMethod: any = undefined;
  showStripeForm: boolean = false;
  currency: string = "EUR";
  stripe: any;
  elements: any;

  netPayTokenInfo: any = null;
  showCreditCardForm: boolean = false;
  name: string = "";
  cardnumber: string = "";
  expirationdate: string = "";
  securitycode: string = "";

  showMercadopagoForm = false;
  transbankTokenize = false;

  loading = false;

  async mounted() {
    Loader.Loading("shopping-cart");
    this.loading = true;
    const localStorageCheckoutParams = LocalStorage.get('checkoutParams');
    if (localStorageCheckoutParams) {
      LocalStorage.delete('checkoutParams');
      const pendingCheckoutParams = JSON.parse(localStorageCheckoutParams) as CheckoutParams;
      const creditCardToken = await this.processTokenization();
      if (creditCardToken) {
        pendingCheckoutParams.tokenId = creditCardToken;
        await this.checkout(null, false, pendingCheckoutParams);
        Loader.Unloading("shopping-cart");
        this.loading = false;
        return;
      }
    }
    Loader.Unloading("shopping-cart");
    this.settings = this.$store.state.settings;

    this.okRss = this.data.okRss !== null ? this.data.okRss : false;
    this.acceptConditions =
      this.data.acceptConditions !== null ? this.data.acceptConditions : false;

    let sid = LocalStorage.getWithTimeout("cc");
    if (sid !== undefined && sid !== null) {
      this.customer = JSON.parse(sid);
      this.cid = this.customer.id;
    }
    this.loading = false;

    this.setUpPayPal();
  }

  get savedPaymentMethods() {
    let items = (this.data.paymentMethods as PaymentMethod[]).filter((pMethod) => {
      return pMethod.tokenId !== null;
    });
    return items;
  }

  get filteredPaymentMethods() {
    return (this.data.paymentMethods as PaymentMethod[]).filter((pMethod) => {
      return pMethod.tokenId == null;
    });
  }

  setCreditCardInfo(info: any) {
    this.error = "";
    this.name = info.name;
    this.cardnumber = info.cardnumber;
    this.expirationdate = info.expirationdate;
    this.securitycode = info.securitycode;
  }

  showSaveCreditCard(pMethod: any) {
    return pMethod.allowTokenization === true && pMethod.tokenId === null;
  }

  async validatePromo(e: any) {
    this.cleanErrors();
    e.preventDefault();

    this.codeApplied = true;
    if (
      this.promoCode === "" ||
      this.promoCode === undefined ||
      this.promoCode === null
    ) {
      return;
    }

    // if promos saleitems must be 1 unit each

    const url = `${Settings.HostName}/api/promos/validate`;
    const data = await new CrudService().httpPost(url, {
      code: this.promoCode,
      customerId: this.cid,
      saleItems: this.getSaleItems(),
      saleIds: this.$store.state.cart.sales.map(function (v) {
        return v.id;
      }),
    });

    if (
      data == null ||
      data === undefined ||
      (data.ok === false && data.errorMsg === null)
    ) {
      this.errorPromo = this.$t("Sorry, the code is not valid").toString();
      return;
    }

    if (data.ok === false) {
      this.errorPromo = data.errorMsg;
      return;
    }
    this.okPromo = this.$t("Disccount Applied").toString();
    this.reloadPayButtonText++;
    this.discount = data.data.discount;
  }

  async checkoutWithCreditCard(e: any) {
    e.preventDefault();
    if (
      Format.IsNull(this.name) ||
      Format.IsNull(this.cardnumber) ||
      Format.IsNull(this.expirationdate) ||
      Format.IsNull(this.securitycode)
    ) {
      this.error = this.$t("Please fill all fields").toString();
      return;
    }

    const pMethod = this.data.paymentMethods.find(
      (y) => y.id === this.paymentMethodId
    );

    if (pMethod === undefined) {
      console.error("not payment method found for id " + this.paymentMethodId);
      return;
    }

    if (pMethod.type === PaymentMethodType.NetPay) {
      return this.netPay();
    }

    if (pMethod.type === PaymentMethodType.AuthorizeNet) {
      return await this.authorizeNet();
    }
  }

  async checkout(e: any, fromCreditCardForm: boolean, customCheckoutParams: any = null) {
    if (e !== null) {
      e.preventDefault();
    }

    let checkoutParams = customCheckoutParams ?? this.buildCheckoutParams();

    if (customCheckoutParams == null) {
      this.cleanErrors();
      this.checkErrors();

      if (!Format.IsNull(this.error)) {
        this.$store.commit("openGlobalError", this.error);
        return;
      }
      const pMethod = this.data
        .paymentMethods
        .find((y) => y.id === this.paymentMethodId);

      if (pMethod !== undefined
        && fromCreditCardForm === false
        && this.tokenId == null
        && (pMethod.type === PaymentMethodType.NetPay || pMethod.type === PaymentMethodType.AuthorizeNet)) {
        this.showCreditCardForm = true;
        return;
      }

      if (pMethod !== undefined 
        && pMethod.type == PaymentMethodType.Transbank && this.saveCreditCard) {
        LocalStorage.add('checkoutParams', JSON.stringify(checkoutParams));
        this.transbankTokenize = true;
        return;
      }
    }

    let url =
      this.cid == 0
        ? `${Settings.HostName}/api/shop/checkout`
        : `${Settings.HostName}/api/shop/checkout-customer`;

    const data = await new CrudService()
      .httpPost(url, checkoutParams);
    this.showCreditCardForm = false;
    const errorMsg =
      this.$t("Ups! Tecnical problems").toString() +
      ". " +
      this.$t("Please try again in a few minutes").toString();

    if (data == null || data === undefined) {
      console.error("Crash checkout " + this.cid);
      this.error = errorMsg;
      this.$store.commit("openGlobalError", this.error);
      return;
    }

    if (data.ok === false) {
      if (
        data.model !== null &&
        data.model.errorMsg.indexOf("netpay3ds-") !== -1
      ) {
        return (window.location.href =
          data.model.errorMsg.split(" netpay3ds-")[1]);
      }
      this.error = data.model.errorMsg !== "" ? data.model.errorMsg : errorMsg;
      this.$store.commit("openGlobalError", this.error);
      return;
    }

    if (data.model !== null && data.model.redsysData !== undefined) {
      this.redirectToRedsys(data.model.redsysData);
      return;
    }

    if (data.model !== null && data.model.paypal !== undefined) {
      return data.model.paypal; // paypal create order
    }

    if (data.model !== null && data.model.stripe !== undefined) {
      return this.stripeSetUp(data.model.stripe, data.model.publicKey);
    }

    if (data.model !== null && data.model.mercadoPago !== undefined) {
      return this.mercadoPago(
        data.model.mercadoPago.pk,
        data.model.mercadoPago.id,
        data.model.mercadoPago.amount,
        data.model.mercadoPago.email,
        data.model.mercadoPago.currency
      );
    }

    if (data.model !== null && data.model.transbank !== undefined) {
      this.redirectToTransbank(data.model.transbank);
      return;
    }

    if (data.ok === true) {
      new ShopService().removeCart();
      return this.$router.push("/cz/ok");
    }
  }

  buildCheckoutParams(): CheckoutParams {
    return {
      saleItems: this.getSaleItems(),
      saleIds: this.$store.state.cart.sales.map((y) => y.saleIds).flat(),
      paymentMethodId: this.paymentMethodId,
      tokenId: this.tokenId,
      promoCode: this.promoCode,
      shopTransportId: Format.IsNull(this.transport) ? null : this.transport.id,
      saveCreditCard: this.saveCreditCard,
      guid: this.$store.state.cart.guid,
      tpvOnlineInfoTemp:
        this.netPayTokenInfo !== null
          ? JSON.stringify(this.netPayTokenInfo)
          : "",
      tpvOnlineNotPersitedInfo: JSON.stringify({
        card: this.cardnumber,
        exp: this.expirationdate,
      }),
      customer: {
        id: this.cid,
        name: this.data.name,
        surname: this.data.surname,
        addressLine1: this.data.addressLine1,
        city: this.data.city,
        town: this.data.town,
        country: this.data.country,
        postalCode: this.data.postalCode,
        email: this.data.email,
        phone: this.data.phone,
        password: this.data.password,
        okRss: this.okRss,
        acceptConditions: this.acceptConditions,
      },
      origin: this.getOrigin(),
    };
  }

  getOrigin() {
    return Format.getOrigin();
  }

  /*** Paypal ***/

  setUpPayPal() {
    this.paypalPaymentMethod = this.data.paymentMethods.find(
      (y) => y.type === PaymentMethodType.PayPal
    );

    if (this.paypalPaymentMethod !== undefined) {
      this.paypalId = this.data.payPalId;
      this.currency = this.data.currency;
      this.loadPaypal();
    }
  }

  loadPaypal() {
    if (!this.paypalIsLoaded) {
      this.createPayPalButtons();
      this.paypalIsLoaded = true;
    }
  }

  createPayPalButtons() {
    const fake: any = this;
    fake
      .$loadScript(
        "https://www.paypal.com/sdk/js?client-id=" +
          this.paypalId +
          "&currency=" +
          this.currency +
          "&intent=authorize&disable-funding=mercadopago,credit,card",
        {
          "data-sdk-integration-source": "button-factory",
        }
      )
      .then(() => {
        this.paypal = window["paypal"];
        this.createPaypalContainerIfNotAvailable();
        const btns = this.paypal.Buttons({
          style: {
            shape: "rect",
            color: "gold",
            layout: "vertical",
            label: "paypal",
            size: "responsive",
          },
          onClick: () => {
            this.paymentMethodId = this.paypalPaymentMethod.id;
          },
          createOrder: async (data) => {
            return this.checkout(null, false);
          },
          onApprove: async (data, actions) => {
            // Authorize the transaction
            const s: any = this;
            actions.order
              .authorize()
              .then(function (authorization) {
                // Get the authorization id
                var authorizationID =
                  authorization.purchase_units[0].payments.authorizations[0].id;

                // Call your server to validate and capture the transaction
                const url = `${Settings.HostName}/api/paypal/callback`;
                return new CrudService().httpPost(url, {
                  orderId: data.orderID,
                  authorizationId: authorizationID,
                });
              })
              .then(function (res) {
                if (res === null) {
                  s.error = "Crash";
                  actions.close();
                  return;
                }
                if (res.ok === false) {
                  s.error = res.errorMsg;
                  console.error(res.errorMsg);
                  actions.close();
                  return;
                }
                if (res.ok === true) {
                  new ShopService().removeCart();
                  actions.redirect(
                    window.location.protocol +
                      "//" +
                      window.location.host +
                      "/cz/ok"
                  );
                }
              });
          },
          onError: (err) => {
            console.error(err);
          },
        });
        setTimeout(function () {
          btns.render("#paypal-button-container");
        }, 3);
      });
  }

  createPaypalContainerIfNotAvailable() {
    if (document.getElementById("paypal-button-container")) {
      return;
    } else {
      const superParent = document.getElementById("payPalParent");
      const parent = document.createElement("div");
      parent.setAttribute("id", "payPalButtons");
      superParent?.appendChild(parent);
      const child = document.createElement("div");
      child.setAttribute("id", "paypal-button-container");
      parent?.appendChild(child);
    }
  }

  /*** Stripe ***/

  stripeSetUp(clientSecret: string, publicKey: string) {
    this.stripePaymentMethod = this.data.paymentMethods.find(
      (y) => y.type === PaymentMethodType.Stripe
    );
    this.showStripeForm = true;

    if (this.stripePaymentMethod !== undefined) {
      const fake: any = this;
      fake.$loadScript("https://js.stripe.com/v3/").then(() => {
        const Stripe = self.Stripe;
        fake.stripe = Stripe(publicKey);
        const appearance = {
          theme: "stripe",
        };
        fake.elements = fake.stripe.elements({ appearance, clientSecret });
        const paymentElementOptions = {
          layout: "tabs",
        };
        const paymentElement = fake.elements.create(
          "payment",
          paymentElementOptions
        );

        setTimeout(function () {
          paymentElement.mount("#payment-element");
        }, 1);
      });
    }
  }

  async stripeCheckout(e: any) {
    e.preventDefault();
    const { error } = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        return_url: document.location.origin + "/stripe-callback",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      this.error = error.message;
    } else {
      this.error = this.$t("Crash").toString();
    }
  }

  /***Authorizenet ***/
  async authorizeNet() {
    await this.checkout(null, true);
  }

  /*** NetPay ***/
  netPay() {
    const expiry = this.expirationdate.split("/");
    let cardInformation = {
      cardNumber: this.cardnumber,
      expMonth: expiry[0],
      expYear: expiry[1],
      cvv2: this.securitycode,
    };

    const fake: any = this;
    fake
      .$loadScript("https://docs.netpay.mx/cdn/v1.3/netpay.min.js")
      .then(() => {
        const NetPay = self.NetPay;

        NetPay.setApiKey(fake.data.netPayPublicKey);
        NetPay.setSandboxMode(fake.data.netPayProdMode === false);

        NetPay.card.validateNumber(cardInformation.cardNumber);
        NetPay.card.validateExpiry(
          cardInformation.expMonth,
          cardInformation.expYear
        );
        NetPay.card.validateCVV(
          cardInformation.cvv2,
          cardInformation.cardNumber
        );
        NetPay.card.validateNumberLength(cardInformation.cardNumber);

        NetPay.token.create(cardInformation, success, error);
        function success(e) {
          fake.netPayTokenInfo = JSON.parse(e.message.data);
          fake.netPayTokenInfo.ccv = cardInformation.cvv2;
          fake.checkout(null, true);
          // fake.showCreditCardForm = false;
        }

        function error(e) {
          fake.error = e.message;
        }
      });
  }

  /** MercadoPago ***/
  mercadoPago(
    pk: string,
    id: number,
    amount: number,
    email: string,
    currency: string
  ) {
    const fake: any = this;
    this.showMercadopagoForm = true;
    setTimeout(() => {
      fake.$loadScript("https://sdk.mercadopago.com/js/v2").then(async () => {
        const MercadoPago = self.MercadoPago;
        const mp = new MercadoPago(pk, {
          locale: "es-AR",
        });
        const bricksBuilder = mp.bricks();

        const renderPaymentBrick = async (bricksBuilder) => {
          // Set up the payment preferences
          const settings = {
            initialization: {
              amount: amount,
              currency_id: currency,
              payer: {
                firstName: this.customer.name,
                lastName: "",
                email: email,
                entityType: "individual",
              },
            },
            customization: {
              visual: {
                style: {
                  theme: "default",
                },
              },
              paymentMethods: {
                debitCard: "all",
                // ticket: "all",
                // atm: "all",
                // onboarding_credits: "all",
                creditCard: "all",
                maxInstallments: 1, // if removed it shows periodic fees options
              },
              installments: 1,
            },
            callbacks: {
              onReady: () => {
                // Callback called when the Brick is ready.
                // You can hide loading indicators here, for example.
              },
              onSubmit: ({ selectedPaymentMethod, formData }) => {
                // Callback called when the submit button is clicked
                formData.rqId = id;
                formData.saveCardToken = this.saveCreditCard;
                const token = LocalStorage.getWithTimeout("ctk");
                const url =
                  process.env.NODE_ENV === "development"
                    ? "http://localhost:8819/api/publicmercadopago/process_payment"
                    : "/api/publicmercadopago/process_payment";
                return new Promise((resolve, reject) => {
                  fetch(url, {
                    // taykusapi
                    method: "POST",
                    headers: {
                      Accept: "application/json",
                      Authorization: "Bearer " + token,
                      "content-type": "application/json-patch+json",
                    },
                    body: JSON.stringify(formData),
                  })
                    .then((response) => response.json())
                    .then((response) => {
                      // Receive the payment result
                      if (response.ok === true) {
                        this.$router.push("/cz/ok");
                        return;
                      }
                      this.showMercadopagoForm = false;
                      this.error = response.errorMsg;
                      resolve();
                    })
                    .catch((error) => {
                      debugger;
                      this.error = error;
                      this.showMercadopagoForm = false;
                      reject();
                    });
                });
              },
              onError: (error) => {
                // Callback called for all Brick error cases
                371180303257522;
                this.error = error;
                this.showMercadopagoForm = false;
                console.error(error);
              },
            },
            saveCardToken: this.saveCreditCard, // Enable saving card data for future use
          };

          // Render the payment form inside the #payment-element div
          window.paymentBrickController = await bricksBuilder.create(
            "payment",
            "payment-element",
            settings
          );
        };

        renderPaymentBrick(bricksBuilder);
      });
    }, 100);
  }

  /*** Vouchers ***/
  async payVoucher(sale: any, saleItem: any, saleId: number) {
    this.error = "";
    this.cleanErrors();
    this.checkErrors(true);

    if (!Format.IsNull(this.error)) {
      this.$store.commit("openGlobalError", this.error);
      return;
    }

    if (sale && sale.paymentType === BookingPaymentType.Shared) {
      const error = this.$t(
        "Sorry, it's not yet possible to pay shared bookings with a voucher, we are working on it"
      ).toString();
      this.$store.commit("openGlobalError", error);
      return;
    }

    const voucherSelectedDom: any = document.getElementById(
      `__voucher_for_${saleId}`
    );
    if (voucherSelectedDom === null) {
      return;
    }
    const vouchers = this.getVouchers(this.data, saleId);

    let paymentMethodId = -1;
    let voucherId = -1;

    if (voucherSelectedDom.value === "") {
      // is only one option
      paymentMethodId = vouchers[0].id;
      voucherId = vouchers[0].voucher.id;
    } else {
      voucherId = Number(voucherSelectedDom.value);
      for (const v of vouchers) {
        if (v.voucher.id === voucherId) {
          paymentMethodId = v.id;
        }
      }
    }

    let url = `${Settings.HostName}/api/shop/checkout-customer`;

    const data = await new CrudService().httpPost(url, {
      saleIds: sale !== null && sale !== undefined ? [sale.id] : null,
      saleItems:
        saleItem !== null && saleItem !== undefined ? [saleItem] : null,
      paymentMethodId: paymentMethodId,
      voucherId: voucherId,
      customer: {
        id: this.cid,
        okRss: this.okRss,
        acceptConditions: this.acceptConditions,
      },
    });

    const errorMsg = this.$t(
      "Ups! Tecnical problems. Please try again in a few minutes"
    ).toString();

    if (data == null || data === undefined) {
      console.error("Crash checkout " + this.cid);
      this.error = errorMsg;
      return;
    }

    if (data.ok === false || data.errors !== undefined) {
      this.error = data.model.errorMsg !== "" ? data.model.errorMsg : errorMsg;
      return;
    }

    const shopService = new ShopService();
    if (saleItem !== null && saleItem !== undefined) {
      shopService.removeSaleItemFromCart(saleItem);
    }

    if (saleId > 0) {
      shopService.removeSaleFromCart(saleId, false);
    }

    if (!Format.IsNull(data.model) && !Format.IsNull(data.model.saleNumber)) {
      // partial payment with money voucher, reload sale bc it is not fully paid
      shopService.addSaleToCart(data.model, null);
      this.$router.go(0);
      return;
    }

    if (this.$store.state.cart.count === 0) {
      return this.$router.push("/cz/ok");
    }
    location.href = "/checkout";
  }

  getVouchers(data: any, saleId: number) {
    var res: any[] = [];
    const vouchers = data.vouchers.filter((y) => y.saleId === saleId);
    for (const voucher of vouchers) {
      for (const v of voucher.vouchers) {
        res.push(v);
      }
    }
    return res;
  }

  /*** Redsys ***/

  redirectToRedsys(redsysData: any) {
    const data = redsysData.data;

    const form: any = this.$refs.redsysFrm;
    form.action = redsysData.url;

    const sVersion: any = this.$refs.ds_SignatureVersion;
    sVersion.value = data.ds_SignatureVersion;

    const sign: any = this.$refs.ds_Signature;
    sign.value = data.ds_Signature;

    const params: any = this.$refs.ds_MerchantParameters;
    params.value = data.ds_MerchantParameters;

    form.submit();
  }

  /*** Transbank ***/

  async processTokenization() {
    const transbankPaymentQuery = this.$route.query as TransbankPaymentQuery;
    const requestId = this.$route.query.tbkRqId;
    if (transbankPaymentQuery.TBK_TOKEN && requestId) {
      const payload = {
        requestId: requestId,
        token: transbankPaymentQuery.TBK_TOKEN,
      };
      if (transbankPaymentQuery.hasOwnProperty("TBK_ID_SESION") && transbankPaymentQuery.hasOwnProperty("TBK_ORDEN_COMPRA")) {
        this.error = this.$t("Save card failed");
        return;
      }
      const url = `${Settings.HostName}/api/transbank/process-tokenization/${payload.requestId}?TBK_TOKEN=${payload.token}`;
      const data = await new CrudService().httpPost(url);
      if (data == null) {
        this.error = this.$t("Crash").toString();
        return;
      }

      if (!data.ok) {
        this.error = data.errorMsg;
        return;
      }
      return data.model.id;
    }
  }

  closeTransbank() {
    this.transbankTokenize = false;
  }

  redirectToTransbank(transbankData: {url: string, token: string}) {
    const form = this.$refs.transbankForm! as HTMLFormElement;
    form.action = transbankData.url;

    const tbkToken = this.$refs.tbkToken! as HTMLInputElement;
    tbkToken.value = transbankData.token;

    form.submit();
  }

  /*** end payment methods ****/

  getSaleItems() {
    let saleItems = this.$store.state.cart.sItems.filter((y) => y.units > 0);
    if (this.promoCode === "") {
      return saleItems;
    }

    // one saleitem per line to calculate promos
    let saleItemsDto: any[] = [];
    for (const sItem of saleItems) {
      for (let j = 0; j < sItem.units; j++) {
        let unitOneSaleItem = Object.assign({}, sItem);
        unitOneSaleItem.units = 1;
        unitOneSaleItem.customerId = this.cid;
        unitOneSaleItem.buyerCustomerId = this.cid;
        saleItemsDto.push(unitOneSaleItem);
      }
    }
    return saleItemsDto;
  }

  getTotal() {
    return this.transport !== null && this.transport !== undefined
      ? this.transport.product.grossPrice +
          this.$store.state.cart.total -
          this.discount
      : this.$store.state.cart.total - this.discount;
  }

  getPayButtonText() {
    const total = this.getTotal();
    if (total > 0) {
      return (
        this.$t("Proceed to Pay").toString() +
        " " +
        this.formatPrice(total.toString())
      );
    }
    return this.$t("Confirm");
  }

  getTotalFormated() {
    return this.formatPrice(this.getTotal().toString());
  }

  selectPaymentMethod(id: number, tokenId: number, type?: PaymentMethodType) {
    this.paymentMethodId = id;
    this.tokenId = tokenId;
    if (type == PaymentMethodType.Transbank) {
      this.saveCreditCard = true;
    }
  }

  cleanErrors() {
    this.reloadErrors++;
    this.errors.name = "";
    this.errors.accountlastname = "";
    this.errors.surname = "";
    this.errors.addressLine1 = "";
    this.errors.city = "";
    this.errors.town = "";
    this.errors.country = "";
    this.errors.postalCode = "";
    this.errors.email = "";
    this.errors.phone = "";
    this.errors.password1 = "";
    this.errors.password2 = "";
    this.error = "";
    this.errorPromo = "";
    this.okPromo = "";
  }

  checkErrors(skipPaymenMethod: boolean = false) {
    const msg = this.$t("Check required fields").toString();
    const transportRequired = this.checkTransportRequired();

    if (Format.IsNull(this.promoCode) === false && this.codeApplied === false) {
      this.error = this.$t(
        "Please press Apply Coupon button to check the code"
      ).toString();
      return;
    }

    if (transportRequired === true || !this.$store.state.loggedIn) {
      if (Format.IsNull(this.data.name)) {
        this.errors.name = msg;
        this.error = msg;
      }

      if (Format.IsNull(this.data.surname)) {
        this.errors.surname = msg;
        this.error = msg;
      }

      if (Format.IsNull(this.data.addressLine1)) {
        this.errors.addressLine1 = msg;
        this.error = msg;
      }

      if (Format.IsNull(this.data.city)) {
        this.errors.city = msg;
        this.error = msg;
      }

      if (Format.IsNull(this.data.town)) {
        this.errors.town = msg;
        this.error = msg;
      }

      if (Format.IsNull(this.data.country)) {
        this.errors.country = msg;
        this.error = msg;
      }

      if (Format.IsNull(this.data.postalCode)) {
        this.errors.postalCode = msg;
        this.error = msg;
      }

      if (Format.IsNull(this.data.email)) {
        this.errors.email = msg;
        this.error = msg;
      }

      if (Format.IsNull(this.data.phone)) {
        this.errors.phone = msg;
        this.error = msg;
      }
    }

    this.error = Format.IsNull(this.error) ? "" : this.error;
    this.reloadErrors = Format.IsNull(this.error)
      ? this.reloadErrors
      : this.reloadErrors + 1;

    if (this.$store.state.loggedIn === false) {
      if (Format.IsNull(this.data.password)) {
        this.errors.password1 = msg;
        this.error = msg;
      }
      if (this.data.password !== this.data.password2) {
        this.errors.password2 = this.$t("Password does not match");
        this.errors.password1 = this.$t("Password does not match");
        this.error += "<br>" + this.$t("Password does not match").toString();
      }
    }

    if (this.transport === null && transportRequired === true) {
      this.error += "<br>" + this.$t("Please select transport").toString();
    }

    if (this.$store.state.cart.count === 0) {
      this.error += "<br>" + this.$t("Your basket is empty!");
    }

    if (
      !skipPaymenMethod &&
      this.paymentMethodId === null &&
      this.getTotal() > 0
    ) {
      this.error +=
        "<br>" + this.$t("Please select a payment method").toString();
    }

    if (this.acceptConditions === false) {
      this.error +=
        "<br> " + this.$t("Please accept our legal conditions").toString();
    }
  }

  checkTransportRequired() {
    return this.$store.state.cart.sItems.some(
      (y) => y.units > 0 && y.product.transportRequired === true
    );
  }

  transportRowClick(transport: any) {
    const dom = document.getElementById("trans_" + transport.id);
    if (dom !== null && dom !== undefined) dom.click();
    this.transport = transport;
  }

  removeSale(e: any, saleId: number) {
    e.preventDefault();
    new ShopService().removeSaleFromCart(saleId);
  }

  removeSaleItem(e: any, saleItem: any) {
    e.preventDefault();
    new ShopService().removeSaleItemFromCart(saleItem);
  }

  increment(sItem: SaleItem) {
    new ShopService().modifyUnits(ModifyMode.Increment, sItem, 1);
  }

  decrement(sItem: SaleItem) {
    new ShopService().modifyUnits(ModifyMode.Decrement, sItem, 1);
  }

  modify(sItem: SaleItem) {
    const units = Number(sItem.units);
    if (isNaN(units)) {
      return;
    }
    new ShopService().modifyUnits(ModifyMode.Exact, sItem, units);
  }

  getPaymentMethodImage(pMethod: any) {
    if (pMethod.tokenId !== null) {
      return "/img/sslPage.png";
    }

    if (pMethod.type === PaymentMethodType.Redsys) {
      return "/img/redsys.png";
    }

    if (pMethod.type === PaymentMethodType.NetPay) {
      return "/img/netpay.png";
    }

    if (pMethod.type === PaymentMethodType.Stripe) {
      return "/img/stripe.png";
    }

    if (pMethod.type === PaymentMethodType.Pending) {
      return "/img/credit-card-clock.svg";
    }

    if (pMethod.type === PaymentMethodType.AuthorizeNet) {
      return "/img/authorizenet.png";
    }

    if (pMethod.type === PaymentMethodType.MercadoPago) {
      return "/img/mercadopago.png";
    }
  }

  getImagePath(img: string) {
    if (img === null || img === undefined || img === "") {
      return this.settings.Uploads + "/" + this.settings.Mainlogo;
    }
    return this.settings.Uploads + "/" + img;
  }

  formatPrice(price: string) {
    return Format.formatCurrency(price);
  }

  isPayPal(pMethod: any) {
    return pMethod.type === PaymentMethodType.PayPal;
  }

  isStripe(pMethod: any) {
    return pMethod.type === PaymentMethodType.Stripe;
  }
}
